/**
 * A simple wrapper for localStorage to keep the data in memory if
 * local storage is not available.
 *
 * We use this wrapper for code that is used by public pages (mixpanel,
 * ab testing).
 * And we use localStorage directly for in-app services (authentication,
 * etc) - here, if we see localStorage issues, we could show a warning
 * to use a compatible browser or try memory storage or some other solution.
 * Right now, we only see public page related issues in Sentry.
 *
 * Local storage operation may fail in different cases:
 * - Opera Mini raises 'Undefined variable: localStorage' error
 * - Local storage is not availabl in private mode in mobile Safari
 *   - There was also `null is not an object (evaluating 'localStorage.getItem')`
 *     error in mobile Safari
 * - If device doesn't have free space, the `QuotaExceededError: DOM Exception 22`
 *   error will be raised.
 **/

// TODO: automatically parse json data and return as object (for now we follow the
// localStorage behavior)
type StorageElement = string // | object
type StorageDict = { [key: string]: StorageElement }

interface Storage {
  setItem(key: string, value: StorageElement): void
  getItem(key: string): StorageElement | null
  removeItem(key: string): void
}

interface MemoryStorage extends Storage {
  hasItem(key: string): boolean
}

// Dictionary to keep the memory storage data.
const _storage: StorageDict = {}

/**
 * Memory storage object, a replacement for localStorage.
 **/
const memoryStorage: MemoryStorage = {
  setItem(key: string, value: StorageElement) {
    _storage[key] = value
  },

  getItem(key: string): StorageElement | null {
    if (this.hasItem(key)) {
      return _storage[key]
    }
    return null
  },

  removeItem(key: string) {
    if (this.hasItem(key)) {
      delete _storage[key]
    }
  },

  hasItem(key: string) {
    return _storage.hasOwnProperty(key)
  },
}

type StorageOperation = (storage: Storage) => StorageElement | null

/**
 * A wrapper to try localStorage operation and
 * fallback to memory storage if operation fails.
 */
function tryLocalStorageOperation(
  operation: StorageOperation,
): StorageElement | null {
  try {
    if (localStorage) {
      return operation(localStorage)
    }
  } catch (err) {
    if (window.__UNIT_TESTING !== true) {
      /* eslint-disable no-console */
      console.warn('localStorage not available')
      /* eslint-enable no-console */
    }
  }
  return operation(memoryStorage)
}

function setItem(key: string, value: StorageElement): void {
  tryLocalStorageOperation((storage: Storage) => {
    storage.setItem(key, value)
    return null
  })
}

function getItem(key: string): StorageElement | null {
  return tryLocalStorageOperation((storage) => {
    return storage.getItem(key)
  })
}

function removeItem(key: string): void {
  tryLocalStorageOperation((storage) => {
    storage.removeItem(key)
    return null
  })
}

export const storage: Storage = {
  setItem,
  getItem,
  removeItem,
}
