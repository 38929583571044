/*
 *  Run the text updates on the summary pages, replace the text on yes variant.
 *  Those updates are only applied on the static generated pages,
 *  the text manipulations don't work on the local env without generating the pages.
 *
 */
export function runTextUpdates() {
  updateCopy(pdfTexts)
}

export const pdfTexts = {
  'ab-13448-read-full-summary': 'Read the full summary at Shortform.',
  'ab-13448-pdf-yellow-box-title':
    'Want to learn the ideas in book.title better than ever?',
  'ab-13448-pdf-yellow-box-paragraph':
    'Shortform summaries help you learn 10x better by:',
  'ab-13448-pdf-yellow-box-list-1':
    'Being 100% clear and logical: you learn <strong>complicated ideas, explained simply</strong>',
  'ab-13448-pdf-yellow-box-list-2':
    'Adding original insights and analysis, <strong>expanding on the book</strong>',
  'ab-13448-pdf-why-best-subtitle':
    "We're the most effective way to learn the ideas in a book and gain new insights.",
  'ab-13448-best-items-red-title': 'Crystal-Clear Logic',
  'ab-13448-best-items-red-paragraph-1':
    'We take confusing ideas and explain them in plain and simple ways. Never get confused by a complicated book again.',
  'ab-13448-best-items-red-paragraph-2': '',
  'ab-13448-best-items-green-title': 'Brilliant New Insights',
  'ab-13448-best-items-green-paragraph-1':
    'We add smart original analysis, connecting ideas in novel ways and discussing key updates since the book was published.',
  'ab-13448-best-items-green-paragraph-2': '',
  'ab-13448-best-items-blue-title': 'Always Concise',
  'ab-13448-best-items-blue-paragraph-1':
    "Your time is valuable, and we don't waste it. We write with zero fluff, making every word and sentence deserve your time.",
  'ab-13448-best-items-blue-paragraph-2': '',
  'ab-13448-pdf-header-title':
    "Book Summary: Learn the book's ideas better than ever.",
  'ab-13448-pdf-header-title-short': "Learn the book's ideas better than ever.",
  'ab-13448-pdf-what-readers-say':
    " This is the best summary of book.title I've ever read. The way you explained the ideas and connected them to other books was amazing.",
}

/*
 * Update texts according to experiment definitions on yes variant
 */
export function updateCopy(texts) {
  const titleBlock = document.querySelector('.ab-13448-doc-title')
  const docTitle = (titleBlock && titleBlock.textContent) || ''

  Object.keys(texts).forEach((key) => {
    const item = document.querySelector('.' + key)
    if (item && key in texts) {
      let text = texts[key]

      // Replace book.title on dynamic blocks.
      if (text.includes('book.title') && docTitle) {
        text = text.replace('book.title', docTitle)
      }
      // Render HTML if we need to
      if (text.includes('<strong>') || text.includes('<em>')) {
        // Checking for .ab-13448-applied, otherwise we will end in endless loop in the observer.
        if (!item.classList.contains('ab-13448-applied')) {
          item.classList.add('ab-13448-applied')
          item.innerHTML = text
        }
      } else {
        item.textContent = text
      }
    }
  })
}
