import { initMixpanel } from '@/init/mixpanel'
import { sentryInit } from '@/init/sentry-init'

const { testimonials } = require('@/assets.v2/js/pagesSite.js')
const { header } = require('@/assets.v2/js/header.js')

import { initAB } from '@/services/ab'
import { assertNotNull } from '@/helpers/typing'
import { setLocation } from '@/helpers/routes'

export function initPageSiteV2(): void {
  initMixpanel()
  sentryInit()

  document.addEventListener('DOMContentLoaded', function () {
    header()
    testimonials()
  })
}

export function onPageLoad(): void {
  initAB()
  setupEmailForm()

  // TODO: fix home page static file generation.
  // Currently, the home page is not being pre-rendered,
  // so we commented out the functions below because they
  // are already running from elsewhere in the code, and we
  // don't want them firing twice (especially Mixpanel.pageView()).
  //
  // applyNavbarButtons()
  // Mixpanel.pageView()
}

export function setupEmailForm(): void {
  const form = document.querySelector('.get-free-summary__form')
  if (form) {
    form.addEventListener('submit', handleEmailSubmit)
  }
}

// Handle signup with email form submit
function handleEmailSubmit(event: Event): void {
  event.preventDefault()

  const email = assertNotNull(
    document.querySelector('.get-free-summary__input') as HTMLInputElement,
  )
  const emailValue = encodeURIComponent(email.value)
  setLocation(`/app/signup?email=${emailValue}`, () => {})
}
