export function trim(
  text: string,
  length: number,
  placeholder: string = '...',
): string {
  if (text.length <= length) {
    return text
  }
  // This is the text to process
  // text = 'This is the text to process'
  // text = text.slice(0,15)   -> "This is the tex"
  // text = text.split(' ')    -> Array(4) [ "This", "is", "the", "tex" ]
  // text = text.slice(0, -1)  -> Array(3) [ "This", "is", "the" ]
  // text = text.join(' ')     -> "This is the"
  return text.slice(0, length).split(' ').slice(0, -1).join(' ') + placeholder
}

/**
 * Replace UUID with '{UUID}' string.
 *
 * We use it to unify Sentry error titles and group similar errors
 * together.
 */
export function replaceUUID(target: string): string {
  const regex =
    /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi
  return target.replace(regex, '{UUID}')
}

/**
 *
 * Capitalize the first letter in the tag.
 *
 * If the tag has two words, that are split by slash, capitalize
 * both of them.
 *
 * We need this to show the correct page title, because we get the
 * tag from the route like 'business/finance', and we need to show
 * it like 'Business/Finance'
 *
 * For the single letter tag like 'lifestyle' it works the same way -
 * the first letter is capitalized: 'Lifestyle'
 *
 * @param tag - string with tag, all lowercase
 * @returns string with tag where the first letter is uppercase.
 */
export function capitalizeTagFirstLetter(tag: string): string {
  if (!tag) {
    return ''
  }

  return tag
    .split('/')
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join('/')
}

/**
 * Truncate search term for the page title.
 *
 * If the search term is longer than 30 chars, we truncate it
 * and add '...' in the end. This is used for the search page
 * title. For example: Search results for "unique title and very long boo..."
 */
export function truncateSearchTerm(term: string): string {
  if (term.length > 30) {
    return term.slice(0, 30) + '...'
  }
  return term
}

/**
 * Convert URL slug to title.
 *
 * Replace '-' with space and capitalize the first letter of each word.
 * For example, 'popular-books' -> 'Popular Books'
 */
export function urlSlugToTitle(urlSlug: string): string {
  return urlSlug
    .split('-')
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')
}
