import { IS_PRERENDERING } from '@/init/settings.ts'

export function testimonials() {
  if (IS_PRERENDERING) {
    return
  }

  // There are two sets of buttons. One for desktop and one for mobile.
  const controlButtons = document.querySelectorAll(
    '.blockquote-people-slider__button, .blockquote-people-slider__mobile-button',
  )
  controlButtons.forEach((control) => {
    control.addEventListener('click', () => toggleActiveSlide(control))
  })

  function toggleActiveSlide(button) {
    const slideOrder = button.getAttribute('slide-order')
    const nextSlide = document.getElementById(`slide-${slideOrder}`)
    const nextSlideControl = document.getElementById(`control-${slideOrder}`)
    // We only select the mobile buttons here because they need to get styled.
    // The desktop buttons are invisible, and therefore don't need to be styled.
    const nextButtonMobile = document.querySelector(
      `.blockquote-people-slider__mobile-button[slide-order="${slideOrder}"]`,
    )

    // Grab the currently active slide and controls...
    const previousSlide = document.querySelector(
      '.blockquote-slider__item.active',
    )
    const previousControl = document.querySelector(
      '.blockquote-people-slider__item.active',
    )
    const previousButtonMobile = document.querySelector(
      '.blockquote-people-slider__mobile-button.active',
    )

    // ...and remove the active class from them.
    previousSlide.classList.remove('active')
    previousControl.classList.remove('active')
    if (previousButtonMobile) {
      previousButtonMobile.classList.remove('active')
    }

    // Add the active class to the next slide and controls.
    nextSlide.classList.add('active')
    nextSlideControl.classList.add('active')
    nextButtonMobile.classList.add('active')

    // Update the CSS variable that controls the position of the testimonial authors.
    const controlsElement = document.querySelector('.blockquote-people-slider')
    controlsElement.style.setProperty('--current-slide-index', slideOrder)
  }
}
