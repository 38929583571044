import axios from 'axios'
import {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

import { APP_VERSION, BACKEND_URL, BACKEND_TIMEOUT } from '@/init/settings'
import { auth } from './auth'
import { configSentryForAxiosError } from '@/helpers/axios'

let configURL = BACKEND_URL
if (configURL.startsWith('http://localhost')) {
  // This allows to use different addresses locally, such
  // as 'http://thinkpad.lan' or 'http://macbook.lan'
  if (window.location.hostname !== '127.0.0.1') {
    configURL = configURL.replace('localhost', window.location.hostname)
  }
}
export const apiURL = configURL + '/api/'

export class Request {
  public _axios: AxiosInstance

  constructor(baseUrl: string, timeout: number) {
    this._axios = axios.create({
      baseURL: baseUrl,
      timeout: timeout,
      headers: {
        'Content-Type': 'application/json',
        'X-Sf-Client': APP_VERSION,
      },
    })

    // Request Interceptor
    this._axios.interceptors.request.use((config: AxiosRequestConfig) =>
      this._addAuth(config),
    )

    // Response Interceptor to handle and log errors
    this._axios.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.data) {
          this._updateAuthMetadata(response)
        }
        return response
      },
      (error: AxiosError) => {
        // Handle Error
        try {
          // Remove token for 401 (Unauthorized) response,
          // means that current token is invalid.
          if (error.response && error.response.status === 401) {
            auth.removeAuth(true)
          }
          this._logError(error)
        } catch (e) {
          // Ignore exceptions in the error handler (for example, if removeAuth
          // fails, in that case we pass the original error further, to Promise.reject().
          console.log(e)
        }
        return Promise.reject(error)
      },
    )
  }

  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this._axios.get<T, R>(url, config)
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this._axios.post<T, R>(url, data, config)
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this._axios.put<T, R>(url, data, config)
  }

  async patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this._axios.patch<T, R>(url, data, config)
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this._axios.delete<T, R>(url, config)
  }

  private _updateAuthMetadata(response: AxiosResponse): void {
    const metadata = response.data.metadata
    if (metadata && metadata.user) {
      // Update the logged in user data from the
      // request metadata, this is needed to dynamically
      // update the `is_limited` and `plan_id` properties as
      // billing information can be changed at any time, depending
      // on what is going on Stripe side.
      auth.updateAuthUser(metadata.user)
    }
  }

  private _addAuth(config: AxiosRequestConfig): AxiosRequestConfig {
    const token = auth.getToken()
    if (token) {
      config.auth = {
        username: token,
        password: '',
      }
    }
    return config
  }

  private _skipError(
    config: AxiosRequestConfig,
    errorResponse: AxiosResponse,
  ): boolean {
    const data = errorResponse.data
    const url = config.url

    // Skip billing errors like
    // {"errors":{"card":["Your card's security code is incorrect."]}}
    if (
      url === 'billing/card' &&
      errorResponse.status === 400 &&
      data.errors &&
      data.errors.card
    ) {
      return true
    }

    // Skip exerice validation errors
    // {"errors":{"answers":["Not all questions are answered"]}}
    if (url === 'workouts/' && errorResponse.status === 400) {
      return true
    }

    // Skip A/B testing errors, there is custom handling in services/ab.ts.
    if (url === 'ab/start' && errorResponse.status === 400) {
      return true
    }
    if (url === 'abp/start' && errorResponse.status === 400) {
      return true
    }

    return false
  }

  private _logError(error: AxiosError): void {
    configSentryForAxiosError(error, this._skipError)
  }
}

const timeout = parseInt(BACKEND_TIMEOUT, 10)
export const request = new Request(apiURL, timeout)
