/**
 * The A/B testing storage module.
 */

import { storage as localStorageWrapper } from '@/helpers/storage'

import { ExperimentsDict } from './types'

const AB_KEY = 'sf_ab_data'
const AB_KEY_CID = 'sf_ab_cid'

export function clientId(): string | null {
  return localStorageWrapper.getItem(AB_KEY_CID)
}

export function setClientId(client_id: string): void {
  localStorageWrapper.setItem(AB_KEY_CID, client_id)
}

export function getExperiments(): ExperimentsDict {
  const data = localStorageWrapper.getItem(AB_KEY)
  if (data) {
    const experiments = JSON.parse(data)
    return experiments
  }
  return {}
}

export function setExperiments(experiments: ExperimentsDict): void {
  localStorageWrapper.setItem(AB_KEY, JSON.stringify(experiments))
}

export function hasExperiment(experiment: string): boolean {
  const experiments = getExperiments()
  return experiments.hasOwnProperty(experiment)
}

export function setExperiment(experiment: string, variant: string): void {
  const experiments = getExperiments()
  // Note: there is special '_EXCLUDED_' value (user is filtered out of the experiment).
  experiments[experiment] = variant
  setExperiments(experiments)
}

export function variant(experiment: string): string | null {
  let variant = null

  const experiments = getExperiments()
  if (experiments.hasOwnProperty(experiment)) {
    variant = experiments[experiment]
  }

  return variant
}

export * as storage from './storage'
