export function assertNotNull<T>(
  value: T | null | undefined,
  message?: string,
): T {
  if (value === null) {
    throw new Error(message || 'Null check failed')
  }
  if (value === undefined) {
    throw new Error(message || 'Undefined check failed')
  }
  return value
}
