import { AuthUser } from '@/models/interfaces.auth'
import {
  logout as mixpanelLogout,
  _setMixpanelUserProperties,
} from '@/analytics/mixpanel'
import { storage } from '../helpers/storage'
import { daysDiff } from '@/helpers/time.native'
import { assertNotNull } from '@/helpers/typing'
import { socialLogout } from '@/models/auth/social'
import { Sentry } from '@/services/sentry'

const TOKEN_KEY = 'auth_token'
const USER_KEY = 'user'

export function loggedIn(): boolean {
  return getToken() !== null
}

export function previouslyLoggedIn(): boolean {
  return loggedIn() === false && getUser() !== null
}

export function getToken(): string | null {
  return storage.getItem(TOKEN_KEY)
}

export function getUserId(): string | null {
  const token = getToken()
  if (!token) {
    return null
  }
  return JSON.parse(atob(token.split('.')[0]))[0]
}

export function getUser(): AuthUser | null {
  const data = storage.getItem(USER_KEY)
  if (data) {
    const user = JSON.parse(data)
    return user
  }
  return null
}

export function getUsername(): string | null {
  const user = getUser()
  if (user) {
    return user.username
  }
  return null
}

export function setUsername(username: string): void {
  const user = getUser()
  if (!user) {
    throw new Error('Can not set username, no user')
  }
  user.username = username
  storage.setItem(USER_KEY, JSON.stringify(user))
}

export function setGa4ClientId(ga4_client_id: string): void {
  const user = getUser()
  if (!user) {
    throw new Error('Can not set ga4_client_id, no user')
  }
  user.ga4_client_id = ga4_client_id
  storage.setItem(USER_KEY, JSON.stringify(user))
}

export function setAdjustData(newAdjustData: {
  adid?: string
  idfa?: string
}): void {
  const user = getUser()
  if (!user) {
    throw new Error('Can not set idfa, no user')
  }
  if (newAdjustData.adid) {
    user.adid = newAdjustData.adid
  }
  if (newAdjustData.idfa) {
    user.idfa = newAdjustData.idfa
  }
  storage.setItem(USER_KEY, JSON.stringify(user))
}

export function getEmail(): string | null {
  const user = getUser()
  if (user) {
    return user.email
  }
  return null
}

export function isPaidPlan(): boolean {
  const user = getUser()
  if (!user) {
    return false
  }

  if (user.is_limited) {
    return false
  }
  return true
}

export function isFreePlan(): boolean {
  return !isPaidPlan()
}

export function isNewUser(): boolean {
  const user = assertNotNull(getUser())

  // Paid user
  if (!user.is_limited) {
    // Is new when trial period is active
    return user.is_trial
  } else {
    // The free_plan (or subscription_bad)
    // is new when within 2 weeks of signup
    return !(daysDiff(user.signup) >= 14)
  }
}

export function updateAuthUser(data: AuthUser): void {
  if (isUserChanged(data)) {
    storage.setItem(USER_KEY, JSON.stringify(data))

    if ((window as any)['__PRERENDER_INJECTED']) {
      // Don't use Mixpanel during prerendering.
      return
    }

    try {
      _setMixpanelUserProperties(data)
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

export function saveAuth(token: string, user: AuthUser): void {
  storage.setItem(TOKEN_KEY, token)
  storage.setItem(USER_KEY, JSON.stringify(user))
}

export function removeAuth(keepUserKey?: boolean): void {
  storage.removeItem(TOKEN_KEY)
  if (!keepUserKey) {
    // Passing `keepUserKey=true` keeps the user key in storage
    // so that we have a reference that the user was logged in
    // before. This is used when forcibly logging out a user
    // so that we can redirect them to the login page instead of
    // the signup page.
    storage.removeItem(USER_KEY)
  }
}

export function isUserChanged(data: AuthUser): boolean {
  const user = getUser()
  if (!user) {
    return true
  } else {
    for (const key in data) {
      if (!(key in user)) {
        return true
      }
      // @ts-ignore: element explicitly has an 'any' type
      // because type AuthUser has no index signature.
      if (data[key] !== user[key]) {
        return true
      }
    }
  }
  return false
}

/**
 * Logout without calling the `/api/auth/logout` endpoint. Removes any
 * auth data from the browser / native device.
 */
export async function localLogout(): Promise<void> {
  removeAuth()
  await socialLogout()

  try {
    mixpanelLogout()
  } catch (error) {
    Sentry.captureException(error)
  }
}

export * as auth from './auth'
