import { App, type AppInfo } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import {
  Device,
  type DeviceInfo,
  type DeviceId,
  type OperatingSystem,
} from '@capacitor/device'

// Imported for webpack: capacitor uses dynamic import
// for web implementation and it is split out into a
// separate chunk by webpack.
// Statically importing it here fixes it.
// eslint-disable-next-line import/no-unassigned-import
import '@capacitor/browser/dist/esm/web.js'
// Imported for webpack.
// eslint-disable-next-line import/no-unassigned-import
import '@capacitor/app/dist/esm/web.js'

import { DeviceWeb } from '@capacitor/device/dist/esm/web.js'
import { storage } from '@/helpers/storage'

import { Sentry } from '@/services/sentry'
import { assertNotNull } from '@/helpers/typing'

export type PlatformType = 'ios' | 'android' | 'web'

const unknownOS: OperatingSystem = 'unknown'

const CAP_MOCK = {
  App: {
    getInfo: (): Promise<AppInfo> => {
      return Promise.resolve({
        name: 'shortform',
        id: 'mock',
        build: 'mock',
        version: 'mock',
      })
    },
  },
  Device: {
    getInfo: (platform: PlatformType): Promise<DeviceInfo> => {
      return Promise.resolve({
        name: 'mock',
        model: 'mock',
        platform: platform,
        operatingSystem: unknownOS,
        osVersion: 'mock',
        manufacturer: 'mock',
        isVirtual: false,
        memUsed: 0,
        diskFree: 10000,
        diskTotal: 10000,
        webViewVersion: 'mock',
      })
    },
    getId: (): Promise<DeviceId> => {
      return Promise.resolve({
        uuid: 'web',
      })
    },
  },
}

/**
 * Monkey-patch the getUid method to use safe
 * localStorage wrapper (doesn't raise error when localStorage is
 * not available).
 *
 * See 'frontend/node_modules/@capacitor/deivice/dist/esm/web.js'
 */
DeviceWeb.prototype.getUid = function getUidSafe(): string {
  if (typeof window !== 'undefined') {
    let uid = storage.getItem('_capuid')
    if (uid) {
      return uid
    }
    uid = (this as any).uuid4() as string
    storage.setItem('_capuid', uid)
    return uid
  }
  return (this as any).uuid4()
}

export interface PlatformData {
  _appInfo: AppInfo | null
  _deviceInfo: DeviceInfo | null
  _deviceId: DeviceId | null

  init: () => Promise<void>
  /**
   * The device platform (lowercase).
   */
  platform: PlatformType
  /**
   * The device model. For example, "iPhone"
   */
  model: string
  /**
   * The version of the device OS.
   *
   * @since 1.0.0
   */
  osVersion: string
  /**
   * The app version.
   * On iOS it's the CFBundleShortVersionString.
   * On Android it's package's versionName.
   */
  appVersion: string
  /**
   * The app build version.
   */
  appBuild: number

  openUrl: (url: string) => Promise<void>
}

/**
 * Capacitor data wrapper.
 */
export const platformData: PlatformData = {
  _appInfo: null,
  _deviceInfo: null,
  _deviceId: null,

  async init(): Promise<void> {
    try {
      this._deviceId = await Device.getId()
      this._deviceInfo = await Device.getInfo()
      if (this._deviceInfo.platform !== 'web') {
        this._appInfo = await App.getInfo()
      } else {
        this._appInfo = {
          name: 'shortform',
          id: 'web',
          build: 'web',
          version: 'web',
        }
      }
    } catch (error: any) {
      // We can expect the
      //   "Device" is not implemented on ios
      // error here during the transition period from
      // capacitor 2 to capacitor 3.
      // It requires both web and native code update, but
      // until native app users install updates, we can
      // have these errors.
      let mockPlatform: PlatformType = 'web'
      if (error.message) {
        if (error.message.indexOf('not implemented on ios') !== -1) {
          mockPlatform = 'ios'
        }
        if (error.message.indexOf('not implemented on android') !== -1) {
          mockPlatform = 'android'
        }
      }

      // Send the error to Sentry.
      Sentry.captureException(error)

      // Use mock data instead of real data.
      // The main information is the `platform` and we know it
      // from the error raised, so most of platform-related
      // features should work.
      this._appInfo = await CAP_MOCK.App.getInfo()
      this._deviceId = await CAP_MOCK.Device.getId()
      this._deviceInfo = await CAP_MOCK.Device.getInfo(mockPlatform)
    }
  },

  get platform(): PlatformType {
    return assertNotNull(this._deviceInfo).platform
  },

  get model(): string {
    return assertNotNull(this._deviceInfo).model
  },

  get osVersion(): string {
    return assertNotNull(this._deviceInfo).osVersion
  },

  get appVersion(): string {
    return assertNotNull(this._appInfo).version
  },

  get appBuild(): number {
    const build = assertNotNull(this._appInfo).build
    return parseInt(build)
  },

  async openUrl(url: string): Promise<void> {
    try {
      await Browser.open({ url: url })
    } catch (error) {
      Sentry.captureException(error)
      window.open(url)
    }
  },
}
