import {
  createRouter as _createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
} from 'vue-router'
import * as TypedAssert from 'typed-assert'

const NopComponent = {
  template: '<span></span>',
}

export function createRouter(routes: RouteRecordRaw[]): Router {
  const router = _createRouter({
    history: createWebHistory(),
    routes: routes,
  })
  return router
}

export function withSiteRoutes(routes: RouteRecordRaw[]): RouteRecordRaw[] {
  return routes.concat(siteRoutes)
}

/**
 * Helper to change the location with page reload.
 *
 * We use it when moving from public sub-apps (site,
 * summary pages, etc) which are rendered as static HTMLs
 * to the dynamic main application.
 *
 * Note: in tests we skip it as everything works dynamically,
 * no static pages here.
 */
export function setLocation(href: string, next: any): void {
  if (window.__UNIT_TESTING === true) {
    return next()
  }
  TypedAssert.isString(href)
  window.location.href = href
}

export const siteRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'site_index',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/', next)
    },
    component: NopComponent,
  },
  {
    path: '/pricing',
    name: 'site_pricing',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/pricing', next)
    },
    component: NopComponent,
  },
  {
    path: '/about',
    name: 'site_about',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/about', next)
    },
    component: NopComponent,
  },
  {
    path: '/terms',
    name: 'site_terms',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/terms', next)
    },
    component: NopComponent,
  },
  {
    path: '/delete_account',
    name: 'site_delete_account',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/delete_account', next)
    },
    component: NopComponent,
  },
  {
    path: '/shortform_video',
    name: 'site_video',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/shortform_video', next)
    },
    component: NopComponent,
  },
  {
    path: '/summaries',
    name: 'site_summaries',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/summaries', next)
    },
    component: NopComponent,
  },
  {
    path: '/privacy',
    name: 'site_privacy',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/privacy', next)
    },
    component: NopComponent,
  },
  {
    path: '/404',
    name: 'public_not_found',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/404', next)
    },
    component: NopComponent,
  },
  {
    path: '/app/login',
    name: 'login',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/app/login', next)
    },
    component: NopComponent,
  },
  {
    path: '/app/signup',
    name: 'signup',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/app/signup', next)
    },
    component: NopComponent,
  },
  {
    path: '/app',
    name: 'books',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/app', next)
    },
    component: NopComponent,
  },
]
