/**
 * The A/B testing interface object.
 */

import { request } from '@/services/request'
import { auth } from '@/services/auth'
import { IS_PRERENDERING } from '@/init/settings'
import { platform } from '@/services/platform'
import { assertNotNull } from '@/helpers/typing'
import { Sentry } from '@/services/sentry'
import { storage } from './storage'
import Cookies from 'js-cookie'

import {
  ABVariantData,
  ABSideloadedResponse,
  CheckResponseHint,
  Experiment,
  Experiments,
  EventData,
  StartEvent,
  ConvertEvent,
} from './types'

/**
 * Start the given `experiment`.
 *
 * Sends reqest to /ab/start endpoint to assign a variant
 * to the client.
 *
 * To identify the client, either uses existing `client_id` from
 * the local storage or gets and saves new `client_id` returned
 * by the backend.
 */
export async function start(
  experiment: Experiment,
  variant: string | null = null,
): Promise<string | null> {
  try {
    if (IS_PRERENDERING) {
      // We don't want to send requests or use experimental variants
      // when pre-rendering pages. See:
      // https://github.com/shortformhq/main/issues/2803
      return null
    }
    if (storage.hasExperiment(experiment)) {
      console.log('Experiment has started already', experiment)
      return storage.variant(experiment)
    }
    const client_id = storage.clientId()
    const info = await platform.deviceInfo()

    // Prepare information about the client and send
    // the backend request to start the experiment.
    const data: StartEvent = {
      client_id: client_id,
      experiment: experiment,
      url: window.location.href,
      referrer: window.document.referrer,
      platform: info.platform,
      os: info.osVersion,
      // Variant is optional, usually server assigns the variant, but
      // sometimes, the variant can come from the client - in the case
      // when the experiment was started on CloudFront.
      variant: variant,
    }

    const apiURL = auth.loggedIn() ? 'ab/start' : 'abp/start'
    let response
    try {
      response = await request.post(apiURL, data)
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        const data = error.response.data
        // Check if there is ab experiment data in the response.
        // This is for the case the experiment has started on the server.
        if (checkResponse(data, CheckResponseHint.abStartError)) {
          // We have updated experiments data from backend, use it
          // to return the variant.
          return experimentVariant(experiment)
        }
      }
      // Rethrow an error to interrupt the process.
      throw error
    }
    const variantData: ABVariantData = response.data.data

    // Save client id if it was not set before and save the
    // assigned variant.
    if (!client_id) {
      storage.setClientId(variantData.client_id)
    }
    storage.setExperiment(experiment, variantData.variant)

    return variantData.variant
  } catch (error: any) {
    // Log errors to sentry and return empty variant / client id.
    // We don't want A/B testing requests to interrupt any user
    // flows, so we don't allow errors to go beyond this method.
    Sentry.captureException(error)
    return null
  }
}

/**
 * Send a conversion event for the specified `experiment`
 * to the server (/ab/event endpoint).
 *
 * The `event` is a name of the conversion event (such as
 * `signup`, `card save`, etc).
 */
export async function event(
  experiment: Experiment,
  event: string,
): Promise<void> {
  try {
    const variant = storage.variant(experiment)
    if (!variant) {
      return
    }
    if (variant === '_EXCLUDED_') {
      return
    }

    const data: ConvertEvent = (await eventData()) as ConvertEvent
    data['event'] = event
    data['experiment'] = experiment
    data['variant'] = variant

    const apiURL = auth.loggedIn() ? 'ab/event' : 'abp/event'
    await request.post(apiURL, data)
  } catch (error: any) {
    Sentry.captureException(error)
  }
}

/**
 * Return an assigned variant for the specified `experiment`.
 *
 * Returns variant value or `null` if experiment has not been
 * started for the user or the user was excluded from the
 * experiment.
 *
 * Note: we don't distinct two `null` cases for now, if we
 * need that in future then we can do it like this:
 * - Experiment has not been started: there is no experiment
 * key in the local storage
 * - User was excluded from the experiment: there is experiment
 * key in the local storage with value "null" instead of variant.
 */
export function experimentVariant(experiment: Experiment): string | null {
  return storage.variant(experiment)
}

/**
 * Get the user's client_id from the `X-Sf-Cid` cookie.
 *
 * We use this for getting cookies set by CloudFront after
 * it starts an experiment thorugh the backend.
 */
export function setVariantFromCookie(experiment: Experiment): void {
  // If the user is logged it, we don't want to
  // overwrite their client_id in local storage.
  const isLoggedIn = auth.loggedIn()
  if (isLoggedIn) {
    return
  }

  const clientID = Cookies.get('X-Sf-Cid')
  const experimentVariant = Cookies.get(experimentCookieName(experiment))

  if (!clientID || !experimentVariant) {
    return
  }

  storage.setClientId(clientID)
  storage.setExperiment(experiment, experimentVariant)
}

/**
 * Convert the given experiment name to its cookie name.
 *
 * @example 'some_random_experiment' -> 'X-AB-Some-Random-Experiment'
 */
export function experimentCookieName(experiment: Experiment): string {
  const words = experiment.split('_')
  const capitilizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  )
  const joinedWords = capitilizedWords.join('-')

  return `X-AB-${joinedWords}`
}

/**
 * Returns client information that is used when starting the experiment.
 *
 * We may decide to exclude the user from the experiment based on url or
 * referrer parameters or platform / os.
 *
 * The data also contains the unique `client_id` to indentify the client
 * when we send the conversion event.
 */
export async function eventData(
  includeExperiments: boolean = false,
): Promise<EventData> {
  const info = await platform.deviceInfo()
  const eventData: EventData = {
    client_id: storage.clientId(),
    url: window.location.href,
    referrer: window.document.referrer,
    platform: info.platform,
    os: info.osVersion,
  }
  if (includeExperiments) {
    eventData['experiments'] = experimentsData()
  }
  return eventData
}

export function experimentsData(): Experiments {
  const experiments = {} as Experiments
  for (const key in storage.getExperiments()) {
    const expKey = key as keyof typeof Experiment
    if (storage.hasExperiment(key)) {
      experiments[expKey] = storage.variant(key as Experiment)
    }
  }
  return experiments
}

/**
 * Check if backend response has experiment data sideloaded.
 *
 * This method is called from the backend service when we expect
 * that the experiment (or experiments) started on the server,
 * for example on login or signup.
 *
 * The 'hint' is a hint from backend service on what exactly is going
 * on, like 'register' on signup and 'login' on login.
 *
 */
export function checkResponse(
  responseData: ABSideloadedResponse,
  hint: CheckResponseHint,
): boolean {
  if (!responseData.ab) {
    Sentry.withScope((scope) => {
      scope.setExtra('responseData', responseData)
      scope.setExtra('hint', hint)
      Sentry.captureMessage('No A/B data found in response')
    })
    return false
  }
  const abData = responseData.ab

  // We have A/B experiments data from backend,
  // update client-side storage with this data.
  const client_id = storage.clientId()

  // New client id replaced with different value from backend is a valid
  // case on login:
  // - Existing opens www.shorform.com on a new device
  // - New client id is assigned and the home page experiment is started
  // - User logs in - we set already existing client_id from the server
  // So we don't log Sentry issue in this case.
  //
  // The signup happens less often, but there is a valid case too;
  // - The user signs up
  // - The user logs out, we keep client id on client
  // - The user signs up again, with another email
  // - Backend returns new client id and we replace it
  // So, eventually, we can remove Sentry log here at all, if there are
  // no other cases.
  if (
    client_id &&
    client_id !== 'undefined' &&
    client_id !== abData.client_id
  ) {
    if (hint === CheckResponseHint.register) {
      Sentry.withScope((scope) => {
        scope.setExtra('frontendClientId', storage.clientId())
        scope.setExtra('frontendExperiments', storage.getExperiments())
        scope.setExtra('backendData', abData)
        scope.setFingerprint(['reset-ab-data-on-signup', hint])
        Sentry.captureMessage('Reset A/B client data on signup')
      })
    }
  }
  if (hint === CheckResponseHint.abStartError) {
    Sentry.withScope((scope) => {
      scope.setExtra('frontendClientId', storage.clientId())
      scope.setExtra('frontendExperiments', storage.getExperiments())
      scope.setExtra('backendData', abData)
      scope.setFingerprint(['reset-ab-data-on-ab-start-error', hint])
      Sentry.captureMessage('Reset A/B client data on ab/start error')
    })
  }

  storage.setClientId(assertNotNull(abData.client_id))
  storage.setExperiments(abData.experiments)

  return true
}

export * as ab from './interface'
