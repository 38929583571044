import { IS_PRERENDERING } from '@/init/settings.ts'

export function header() {
  if (IS_PRERENDERING) {
    return
  }

  const hamburger = document.querySelector('.hamburger')
  const header = document.querySelector('.header')
  const nav = document.querySelector('.nav')

  if (!header) {
    // Exit early if there's no header, like on the /extension-auth page. See #11056
    return
  }

  window.addEventListener('scroll', handleHeaderVisibility)
  window.addEventListener('load', addHeaderHeightCssVariables, false)
  window.addEventListener('resize', hideMobileMenuOnDesktop)
  if (hamburger) {
    hamburger.addEventListener('click', toggleMobileMenu)
  }

  let initialHeaderHeight = 0
  let lastScrollPosition = 0

  function addHeaderHeightCssVariables() {
    // Grabs the height of the header and sets it as a CSS variable
    // so that other items can reference it.
    initialHeaderHeight = header?.offsetHeight || 0

    // This is the initial height of the header. It's constant.
    document.documentElement.style.setProperty(
      '--initial-header-height',
      `${initialHeaderHeight}px`,
    )

    // This is the current height of the header. It's updated
    // to reflect when it's being shown or hidden.
    document.documentElement.style.setProperty(
      '--current-header-height',
      `${initialHeaderHeight}px`,
    )
  }

  function handleHeaderVisibility() {
    // Hide the header when scrolling down ('hidden'),
    // show it with drop shadow when scrolling up ('fixed'),
    // and show it without drop shadow when it's within 80px from the top ('top')
    const currentScrollPosition = window.scrollY
    let headerStatus

    if (currentScrollPosition < 80) {
      headerStatus = 'top'
    } else if (currentScrollPosition > lastScrollPosition) {
      headerStatus = 'hidden'
    } else {
      headerStatus = 'fixed'
    }

    header.classList.remove('header--hidden', 'header--fixed', 'header--top')
    header.classList.add(`header--${headerStatus}`)
    updateHeaderCssVariableHeight(headerStatus)

    lastScrollPosition = currentScrollPosition
  }

  function updateHeaderCssVariableHeight(headerStatus) {
    // Update the current-header-height CSS variable to reflect
    // the status of the header
    let currentHeaderHeight

    if (headerStatus === 'hidden') {
      currentHeaderHeight = 0
    } else {
      currentHeaderHeight = initialHeaderHeight
    }

    document.documentElement.style.setProperty(
      '--current-header-height',
      `${currentHeaderHeight}px`,
    )
  }

  function toggleMobileMenu() {
    if (hamburger.classList.contains('active')) {
      hamburger.classList.remove('active')
      header.classList.remove('active')
      nav.classList.remove('active')
      document.body.classList.remove('hidden')
    } else {
      hamburger.classList.add('active')
      header.classList.add('active')
      nav.classList.add('active')
      document.body.classList.add('hidden')
    }
  }

  function hideMobileMenuOnDesktop() {
    if (window.innerWidth >= 992) {
      hamburger.classList.remove('active')
      header.classList.remove('active')
      nav.classList.remove('active')
      document.body.classList.remove('hidden')
    }
  }
}
