import { MIXPANEL_TOKEN, MIXPANEL_DEBUG } from '@/init/settings'

// Init mixpanel library.
// We don't have 'mixpanel' when rendering static pages.
export function initMixpanel(): void {
  // @ts-ignore Property 'mixpanel' does not exist on type 'Window'.
  if (window.mixpanel) {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: MIXPANEL_DEBUG,
      track_pageview: false,
      loaded: () => {
        ;(window as any).__MIXPANEL_LOADED = true
      },
    })
  }
}
